<template>
  <layout class="main-cols">
    <validation-observer
      ref="observer"
      v-slot="{ validate }"
      class="w-full text-left"
    >
      <div
        class="container is-fluid lg:ml-0"
        v-if="getDisposicion || getCuentaCP || getGarantia"
      >
        <div class="columns">
          <div class="column is-8 text-left">
            <div class="columns is-multiline">
              <div class="column p-0 flex items-end">
                <b-icon
                  icon="arrow-left"
                  class="p-5 rounded-full ml-1 cursor-pointer"
                  :class="{
                    'has-text-primary has-background-primary-02':
                      infoDocumento.pasivo == 0,
                    'has-text-pasivo has-background-pasivo-04':
                      infoDocumento.pasivo
                  }"
                  @click.native="$router.back()"
                />
              </div>
              <div
                class="column p-0 text-right"
                v-if="hasPermiso && tipo == 'disposicion'"
              >
                <p class="font-bold">Pagos especiales</p>
                <div class="field mr-0 mt-1">
                  <b-switch
                    v-model="pagos_especiales"
                    :type="
                      getDisposicion.pasivo == 1 ? 'is-pasivo' : 'is-primary'
                    "
                    size="is-medium"
                    class="mr-0"
                  />
                </div>
              </div>
              <div class="column is-12">
                <p class="text-3xl font-bold">
                  Aplicar
                  {{ infoDocumento.tipo_pago }}
                  a
                  {{ infoDocumento.nombre }}
                  {{
                    tipo == "disposicion"
                      ? getDisposicion.pasivo == 0
                        ? "Activa"
                        : "Pasiva"
                      : ""
                  }}
                  <preview
                    :tipo="tipo == 'disposicion' ? 'credito' : tipo"
                    :id="parseInt(id)"
                    :color="
                      infoDocumento.pasivo == 0
                        ? 'has-text-primary'
                        : 'has-text-pasivo'
                    "
                    :border="
                      infoDocumento.pasivo == 0
                        ? 'has-border-primary'
                        : 'has-border-pasivo'
                    "
                    >#{{ infoDocumento.id }}</preview
                  >
                </p>
              </div>
              <div class="column is-12" v-if="tipo == 'cuentaCP'">
                <div class="columns">
                  <div class="column">
                    <p class="has-text-grey-light">
                      Saldo total de la {{ infoDocumento.nombre }}
                    </p>
                    <p>
                      {{ getCuentaCP.saldo | currency | hp }}
                      {{ getCuentaCP.moneda.clave }}
                    </p>
                  </div>
                  <div class="column">
                    <p class="has-text-grey-light">
                      Deuda al día de hoy
                    </p>
                    <p>
                      {{ getCuentaCP.saldo | currency | hp }}
                      {{ getCuentaCP.moneda.clave }}
                    </p>
                  </div>
                  <div class="column">
                    <p class="has-text-grey-light">
                      Tipo de operación
                    </p>
                    <p>
                      {{ getCuentaCP.tipo_de_operacion.nombre }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="column is-12" v-if="tipo == 'garantia'">
                <div class="columns">
                  <div class="column">
                    <p class="has-text-grey-light">
                      Valor
                    </p>
                    <p>
                      {{ getGarantia.valor_verificado | currency | hp }}
                      {{
                        getGarantia.moneda ? getGarantia.moneda.clave : "MXN"
                      }}
                    </p>
                  </div>
                  <div class="column">
                    <p class="has-text-grey-light">
                      Deuda al día de hoy
                    </p>
                    <p>
                      {{ getGarantia.saldo_capital | currency | hp }}
                      {{
                        getGarantia.moneda ? getGarantia.moneda.clave : "MXN"
                      }}
                    </p>
                  </div>
                  <div class="column">
                    <p class="has-text-grey-light">
                      Recuperación
                    </p>
                    <p>
                      {{ getGarantia.recuperacion | currency | hp }}
                      {{
                        getGarantia.moneda ? getGarantia.moneda.clave : "MXN"
                      }}
                    </p>
                  </div>
                  <div class="column">
                    <p class="has-text-grey-light">
                      Recuperación extraordinaria
                    </p>
                    <p>
                      {{
                        getGarantia.recuperacion_extraordinaria | currency | hp
                      }}
                      {{
                        getGarantia.moneda ? getGarantia.moneda.clave : "MXN"
                      }}
                    </p>
                  </div>
                  <div class="column">
                    <p class="has-text-grey-light">
                      Saldo verificado
                    </p>
                    <p class="truncate-3">
                      {{ getGarantia.verificado }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="column is-12" v-if="tipo == 'disposicion'">
                <div class="columns">
                  <div class="column">
                    <p class="has-text-grey-light">Saldo total del crédito</p>
                    <p>
                      {{ getDisposicion.saldos.total | currency | hp }}
                      {{ getDisposicion.moneda.clave }}
                    </p>
                  </div>
                  <div class="column">
                    <p class="has-text-grey-light">Vigente</p>
                    <preview
                      tipo="saldo_vigente"
                      :saldos="getDisposicion.saldos"
                      :moneda="getDisposicion.moneda.clave"
                      :pasivo="getDisposicion.pasivo ? true : false"
                      :color="
                        getDisposicion.pasivo == 0
                          ? 'has-text-primary'
                          : 'has-text-pasivo'
                      "
                      :border="
                        getDisposicion.pasivo == 0
                          ? 'has-border-primary'
                          : 'has-border-pasivo'
                      "
                    >
                      {{ getDisposicion.saldos.vigente | currency }}
                      {{ getDisposicion.moneda.clave }}
                      <fa-icon icon="chevron-right" />
                    </preview>
                  </div>
                  <div class="column">
                    <p class="has-text-grey-light">Vencido</p>
                    <preview
                      tipo="saldo_vencido"
                      :saldos="getDisposicion.saldos"
                      :moneda="getDisposicion.moneda.clave"
                      color="has-text-danger"
                      border="has-border-danger"
                    >
                      {{ (getDisposicion.saldos.vencidos +
                          getDisposicion.saldos.interes_moratorio ? getDisposicion.saldos.interes_moratorio.valor : 0 +
                          getDisposicion.saldos.iva_interes_moratorio ? getDisposicion.saldos.iva_interes_moratorio.valor : 0 +
                          getDisposicion.saldos.interes_moratorio_provisionado ? getDisposicion.saldos.interes_moratorio_provisionado.valor : 0 +
                          getDisposicion.saldos.iva_interes_moratorio_provisionado ? getDisposicion.saldos.iva_interes_moratorio_provisionado.valor : 0
                      ) | currency }}
                      {{ getDisposicion.moneda.clave }}
                      <fa-icon icon="chevron-right" />
                    </preview>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <tabs
                  v-if="tipo == 'disposicion'"
                  :tabs="tabs"
                  :pasivo="getDisposicion.pasivo == 1 ? true : false"
                  type="default"
                  v-model="activeTab"
                />
                <section v-if="activeTab == 0">
                  <config-general
                    v-model="config"
                    :id="id"
                    :tipos-saldo="tipos_saldo"
                    :condonaciones="condonaciones"
                    :quitas="quitas"
                    :tipo="tipo"
                    :recuperacion_extraordinaria="getGarantia && getGarantia.recuperacion_extraordinaria"
                  />
                </section>
                <!-- <section v-if="activeTab == 1">
                  <accesorios
                    :accesorios="getDisposicion.costos_extra"
                    :capital="getDisposicion.capital_otorgado"
                    @checked="val => (accesorios_pagar = val)"
                  />
                </section> -->
                <section v-if="activeTab == 1">
                  <p class="font-bold text-lg mb-4">
                    Cambiar el orden de prelación
                  </p>
                  <orden-prelacion :pago="true" @ordered="setOrderPrelacion" />
                </section>
                <section v-if="activeTab == 2">
                  <condonaciones-quitas
                    :pasivo="getDisposicion.pasivo == 1 ? true : false"
                    tipo="condonacion"
                    :lista="catalogoCondonaciones"
                    v-model="condonaciones"
                  />
                </section>
                <section v-if="activeTab == 3">
                  <condonaciones-quitas
                    :pasivo="getDisposicion.pasivo == 1 ? true : false"
                    tipo="quita"
                    :lista="catalogoQuitas"
                    v-model="quitas"
                  />
                </section>
                <section v-if="activeTab == 4">
                  <castigo
                    v-model="castigo"
                    :id="id"
                    :pasivo="getDisposicion.pasivo == 1 ? true : false"
                  />
                </section>
              </div>
            <div class="column is-12" v-if="getDisposicion && getDisposicion.desglose_saldos_credito">
              <p class="font-bold has-text-primary text-xl text-left">
                Saldo de la disposición (MXN)
              </p>
              <saldo-table :desglose="getDisposicion.desglose_saldos_credito" />
            </div>
            </div>
          </div>
          <div class="column is-4">
            <resumen-pago
              :config="config"
              :accesorios="accesorios_pagar"
              :pagos-especiales="pagos_especiales"
              :condonaciones="condonaciones"
              :quitas="quitas"
              :castigo="castigo"
              :order-changed="is_order_changed"
              :tipos-saldo="tipos_saldo"
              :validate="validate"
              :tipo="tipo"
              :id="id"
            />
          </div>
        </div>
      </div>
    </validation-observer>
  </layout>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import OrdenPrelacion from "@/components/configuracion/tesoreria/OrdenPrelacion";
import CondonacionesQuitas from "@/components/pago/CondonacionesQuitas";
import Castigo from "@/components/pago/Castigo";
import ConfigGeneral from "@/components/pago/ConfigGeneral";
// import Accesorios from "@/components/pago/Accesorios";
import Tabs from "@/components/generals/Tabs";
import ResumenPago from "@/components/pago/ResumenPago";
import SaldoTable from "@/components/generals/SaldoTable";
import { mapGetters } from "vuex";

export default {
  name: "AplicarPago",
  data() {
    return {
      tipo: null,
      id: null,
      is_modal_opened: false,
      modal_tipo: null,
      tipos_saldo: [],
      is_order_changed: false,
      activeTab: 0,
      condonaciones: [],
      quitas: [],
      accesorios_pagar: [],
      castigo: null,
      config: {
        monto: null,
        instrumento_monetario: null,
        cuenta_banco: null,
        persona_cuenta_banco: null,
        moneda: 1,
        tipo_cambio_pactado: null,
        interes: null,
        iva: null,
        devolucion: null
      },
      pagos_especiales: false,
      condonaciones_quitas: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 29]
    };
  },
  components: {
    ResumenPago,
    OrdenPrelacion,
    CondonacionesQuitas,
    Castigo,
    ConfigGeneral,
    Tabs,
    // Accesorios,
    ValidationObserver,
    SaldoTable,
  },
  watch: {
    pagos_especiales(val) {
      if (!val) {
        this.activeTab = 0;
      }
    },
    getMe(val) {
      if (val) {
        let modulo = val.modulos[3].submodulos.find(
          x => x.nombre == "Pagos especiales"
        ).id;
        this.$store.dispatch("empresa/getPersonasPermisosSubmodulo", modulo);
      }
    },
    $route: {
      handler: function() {
        if (
          this.tipo !== this.$route.params.tipo ||
          this.id !== parseInt(this.$route.params.idObjeto)
        ) {
          this.tipo = this.$route.params.tipo;
          this.id = parseInt(this.$route.params.idObjeto);

          this.mountedFn(this.tipo, this.id);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /*
    Función que se encarga de establecer un orden de prelacion custom
    @new_order (Array) array de ids de tipo saldo
    */
    setOrderPrelacion(new_order) {
      this.tipos_saldo = new_order;
      let pagos = this.getTiposSaldo.map(saldo => {
        return saldo.id;
      });
      var is_same =
        pagos.length === new_order.length &&
        pagos.every(function(element, index) {
          return element === new_order[index];
        });
      if (is_same) {
        this.is_order_changed = false;
        return;
      }
      this.is_order_changed = true;
    },
    mountedFn(tipo, id) {
      switch (tipo) {
        case "disposicion":
          this.$store.dispatch("pagos/getDisposicion", id).then(disposicion => this.saldoReal(disposicion.costos_extra || []));
          break;
        case "cuentaCP":
          this.$store.dispatch("pagos/getCuentaCP", id);
          break;
        case "garantia":
          this.$store.dispatch("garantias/getPersonas", id);
          if (this.$route.query.pago == 1) {
            this.$store.dispatch("pagos/getGarantiaPasivo", id);
          } else {
            this.$store.dispatch("pagos/getGarantiaActivo", id);
          }
          break;
      }
      if (this.getTiposSaldo == null) {
        this.$store.dispatch("catalogos/getTiposSaldo").then(data => {
          this.tipos_saldo = JSON.parse(JSON.stringify(data)).map(saldo => {
            return saldo.id;
          });
        });
      } else {
        this.tipos_saldo = JSON.parse(JSON.stringify(this.getTiposSaldo)).map(
          saldo => {
            return saldo.id;
          }
        );
      }
    },
    saldoReal(accesorios){
      if(accesorios.length > 0){
        accesorios.forEach(accesorio =>{
          if(accesorio.suma_pagos_cantidad !== null && accesorio.suma_pagos_cantidad > 0){
            accesorio.cantidad -= accesorio.suma_pagos_cantidad; 
          }
          if(accesorio.suma_pagos_iva !== null && accesorio.suma_pagos_iva > 0){
            accesorio.iva -= accesorio.suma_pagos_iva; 
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters("catalogos", [
      "getInstrumentosMonetarios",
      "getMonedas",
      "getTiposSaldo"
    ]),
    ...mapGetters("pagos", ["getDisposicion", "getCuentaCP", "getGarantia"]),
    ...mapGetters(["getFechaCierre", "getMe"]),
    ...mapGetters("empresa", ["getPersonasPermisosSubmodulo"]),
    tabs() {
      return [
        {
          label: "Datos generales"
        },
        // {
        //   label: "Accesorios",
        //   tags: [
        //     {
        //       id: "activos",
        //       number: this.accesoriosActivos,
        //       type: "is-primary"
        //     },
        //     {
        //       id: "retardo",
        //       number: this.accesoriosRetardados,
        //       type: "is-danger"
        //     }
        //   ]
        // },
        {
          label: "Orden de prelación",
          visible: this.pagos_especiales
        },
        {
          label: "Condonaciones",
        },
        {
          label: "Quitas",
          visible: this.pagos_especiales
        },
        {
          label: "Castigos",
          visible: this.pagos_especiales
        }
      ];
    },
    accesoriosActivos() {
      if (this.getDisposicion.costos_extra && this.getDisposicion.costos_extra.length > 0) {
        return this.getDisposicion.costos_extra.filter(
          x =>
            this.$moment(x.fecha_vencimiento) >
            this.$moment(this.getFechaCierre)
        ).length;
      }
      return 0;
    },
    accesoriosRetardados() {
      if (this.getDisposicion.costos_extra && this.getDisposicion.costos_extra.length > 0) {
        return this.getDisposicion.costos_extra.filter(
          x =>
            this.$moment(x.fecha_vencimiento) <=
            this.$moment(this.getFechaCierre)
        ).length;
      }
      return 0;
    },
    hasPermiso() {
      if (this.getPersonasPermisosSubmodulo != null && this.getMe != null) {
        let current_user = this.getPersonasPermisosSubmodulo.find(
          x => x.id == this.getMe.id
        );
        return current_user ? true : false;
      }
      return false;
    },
    catalogoCondonaciones() {
      let saldos = [];
      Object.keys(this.getDisposicion.saldos).filter(saldo => saldo.includes('moratorio')).forEach(element => {
        if (
          typeof this.getDisposicion.saldos[element] == "object" &&
          this.getDisposicion.saldos[element].tipos_saldo_id != undefined &&
          this.condonaciones_quitas.includes(
            this.getDisposicion.saldos[element].tipos_saldo_id &&
              this.getTiposSaldo.find(
                x => x.id == this.getDisposicion.saldos[element].tipos_saldo_id
              )?.id
          )
        ) {
          saldos.push({
            tipo_saldo_id: this.getDisposicion.saldos[element].tipos_saldo_id,
            tipo_saldo_nombre: this.getTiposSaldo.find(
              x => x.id == this.getDisposicion.saldos[element].tipos_saldo_id
            ).nombre,
            valor: this.getDisposicion.saldos[element].valor,
            disabled:
              this.getDisposicion.saldos[element].valor == 0 ? true : false
          });
        }
      });
      return saldos;
    },
    catalogoQuitas() {
      let saldos = [];
      Object.keys(this.getDisposicion.saldos).filter(saldo => !saldo.includes('moratorio')).forEach(element => {
        if (
          typeof this.getDisposicion.saldos[element] == "object" &&
          this.getDisposicion.saldos[element].tipos_saldo_id != undefined &&
          this.condonaciones_quitas.includes(
            this.getDisposicion.saldos[element].tipos_saldo_id &&
              this.getTiposSaldo.find(
                x => x.id == this.getDisposicion.saldos[element].tipos_saldo_id
              )?.id
          )
        ) {
          saldos.push({
            tipo_saldo_id: this.getDisposicion.saldos[element].tipos_saldo_id,
            tipo_saldo_nombre: this.getTiposSaldo.find(
              x => x.id == this.getDisposicion.saldos[element].tipos_saldo_id
            ).nombre,
            valor: this.getDisposicion.saldos[element].valor,
            disabled:
              this.getDisposicion.saldos[element].valor == 0 ? true : false
          });
        }
      });
      return saldos;
    },
    // tipo_pago: 1 = pago; 0 = cobro;
    infoDocumento() {
      switch (this.tipo) {
        case "disposicion":
          return {
            nombre: "credito",
            pasivo: this.getDisposicion.pasivo,
            tipo_pago: this.getDisposicion.pasivo ? "pago" : "cobro",
            id: this.getDisposicion.creditos_autorizados_id,
          };
        case "cuentaCP":
          return {
            nombre:
              this.getCuentaCP.cobro == 1
                ? "Cuenta a cobrar"
                : "Cuenta a pagar",
            pasivo: !this.getCuentaCP.cobro,
            tipo_pago: !this.getCuentaCP.cobro ? "pago" : "cobro",
            id: this.getCuentaCP.id,
          };
        case "garantia":
          return {
            nombre: "Garantía",
            pasivo: 0,
            tipo_pago: this.$route.query.pago.split('?tk=')[0] == 1 ? "pago" : "cobro",
            id: this.getGarantia.garantia.id,
          };
        default:
          return {
            nombre: this.tipo
          };
      }
    }
  },
  async mounted() {
    this.tipo = this.$route.params.tipo;
    // await this.mountedFn(this.tipo, this.id);
    this.id = parseInt(this.$route.params.idObjeto);
  }
};
</script>
